import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../../components/LayoutFund"
import { theme } from "../../../components/Theme"

/** static sections */
import FundLeadershipMikeSection from "../../../components/static-sections/FundLeadershipMikeSection"

/** dynamic sections */
import HeroInvestImage from "../../../components/dynamic-sections/HeroInvestImage"
import HeroLeadershipText from "../../../components/dynamic-sections/HeroLeadershipText"
import HeroVideoFund from "../../../components/dynamic-sections/HeroVideoFund"

/** svg */
import NorhartBlackCircleIcon from "../../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const mkieFundLeadership: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/leadership/mike-kaeding",
          name: "Mike Kaeding | Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Mike Kaeding | Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImage
        items={[
          {
            image: "/fund/leadership/mike-hero.png",
          },
        ]}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroLeadershipText
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="EXECUTIVE LEADERSHIP"
        title="Mike Kaeding, Chief Executive Officer"
        formatSubTitle={() => (
          <>
            Mike is the son of Ed Kaeding, the founder of Norhart Inc. In the mid-1990s, Ed began designing,
            constructing, and renting apartment buildings in the Minneapolis area. Over the years, Norhart has become a
            sophisticated property developer that has built over 900 modern and affordable multi-family housing units.
            Today, Mike is the President and CEO of Norhart, a name that reflects his proud Norwegian heritage and his
            dedication to creating thriving communities, which are the core of Norhart's housing developments.
            <br />
            <br />
            Mike's family has been farming for generations. His great grandparents on both sides immigrated to the US
            over a century ago to cultivate the grasslands of North Dakota. They were the earliest property developers
            in a way, constructing self-sufficient farming communities from the ground up under incredibly harsh
            conditions. By the time Mike's grandparents retired from farming, they had saved close to a million dollars,
            which was the seed capital Mike's father used to fund some of Norhart's earliest projects.
            <br />
            <br />
            Mike spent most of his childhood working on Norhart's construction sites, doing odd jobs like cleaning up
            and purchasing supplies. Although he was more interested in computers than concrete, Mike worked hard and
            was soon promoted to junior foreman, and eventually superintendent of Norhart's construction team, all while
            completing a computer science degree at the University of Minnesota.
            <br />
            <br />
            For many years, Mike resisted joining Norhart because he wanted to build a career on his own merit and not
            attach himself to his father's success. During this time, he researched the perception of distance in
            virtual reality (VR) systems at the University of Minnesota, designed 3D imaging for videogames and mapping
            programs, and worked at several start-ups, including toy company Q-Ba-Maze and fruit delivery service
            Fruitshare. In 2009, Mike returned to Norhart after realizing that he could "make a dent in the universe"
            (as Steve Jobs once said) by building quality affordable housing.
            <br />
            <br />
            Since then, Mike has breathed new life into the company with his clever ideas, including revolutionizing
            Norhart's construction methods with manufacturing principles, designing an assembly line construction
            process that has saved the company time and money. By the time Mike took over from his father in 2014,
            Norhart had doubled in size.
            <br />
            <br />
            As CEO, Mike has streamlined operations, built an international supply chain, and moved architecture and
            engineering in-house, driving Norhart's construction costs down by approximately 25% compared to its
            competitors. Under Mike's leadership, Norhart has become a $200 million construction company, completing
            more than 650 units over five projects to-date, and is on track to complete an additional 600 units by 2025.
            His goal is for Norhart to manage close to 200,000 multifamily units within the next decade, which means
            constructing close to 60,000 units per year.
            <br />
            <br />
            Mike has incorporated philanthropy and social responsibility into the Norhart brand, publicly supporting
            local homeless shelters and food banks. Norhart has aligned itself with Hearts and Hammers, a charity
            repairing the homes of people in need, and has created a scholarship for students attending trade school.
          </>
        )}
        subNote=""
        image=""
        imageAlt=""
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundLeadershipMikeSection colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
export default mkieFundLeadership
